import React from 'react'
import chartbtn from '../assets/chartbtn.png'
import butmat2 from '../assets/butmat2.png'
import about3 from '../assets/about3.png'
import about2 from '../assets/about2.png'
import about1 from '../assets/about1.png'
import about1mob from '../assets/about1mob.png'
import about2mob from '../assets/about2mob.png'
import about3mob from '../assets/about3mob.png'
import bullpic from '../assets/bullpic.png'
import roll1 from '../assets/roll1.png'
import gifright from '../assets/gifright.gif'
import rollstraight from '../assets/rollstraight.png'

function About() {
    return (
        <div className="thig">
            <div className='relative flex lg:flex-row flex-col items-center justify-center w-full'>


                <div className="left lg:w-1/2 flex flex-col justify-center items-center">
                    <img src={bullpic} alt="bullpic" className='w-[500px] p-5' />

                </div>
                <div className="right lg:w-1/2 flex flex-col justify-center items-center gap-5 p-4">
                    <h2 className='text-[#FFFCD3]  lg:text-7xl text-4xl'>ABOUT XRP MATT</h2>
                    <img src={about1} alt="about1" className='w-[650px] lg:block hidden' />
                    <img src={about2} alt="about2" className='w-[650px] lg:block hidden' />
                    <img src={about3} alt="about3" className='w-[650px] lg:block hidden' />

                    <img src={about1mob} alt="about1mob" className='w-[650px] lg:hidden block' />
                    <img src={about2mob} alt="about2mob" className='w-[650px] lg:hidden block' />
                    <img src={about3mob} alt="about3mob" className='w-[650px] lg:hidden block' />
                    <div className="btns flex justify-center items-center lg:gap-40 gap-2">
                        <img src={chartbtn} alt="chartbtn" className='lg:w-[200px] w-[130px]' />
                        <img src={butmat2} alt="butmat2" className='lg:w-[200px] w-[130px]' />
                    </div>
                </div>
            </div>
            {/* <img src={roll1} alt="roll1" className='w-full ' /> */}
            <img src={gifright} alt="gifright" className='lg:my-20 -ml-10 my-10 lg:w-[150%] w-[120%] rotate-6 max-w-none' />
        </div>
    )
}

export default About
import React from 'react'
import roll2 from '../assets/roll2.png'
import roll1 from '../assets/roll1.png'
import machomatt from '../assets/machomatt.png'
import gifright from '../assets/gifright.gif'

function Roll2() {
    return (
        <div className='relative flex flex-col items-center justify-center w-full'>
            {/* <img src={roll1} alt="roll1" className='absolute z-20 ' /> */}
            <img src={machomatt} alt="roll2" className='lg:w-[700px] w-[200px] z-10 mb-20 opacity-0' />
            {/* <img src={roll2} alt="roll2" className='absolute  ' /> */}


            <img src={gifright} alt="gifright" className='absolute lg:my-20 -ml-10 my-10 lg:w-[120%] w-[120%] rotate-6 max-w-none' />
            <img src={gifright} alt="gifright" className='absolute lg:my-20 -ml-10 my-10 lg:w-[120%] w-[120%] -rotate-6 max-w-none' />

        </div>
    )
}

export default Roll2
import React from 'react'
import create1 from '../assets/create-1.png'
import create2 from '../assets/create-2.png'
import create from '../assets/create.png'
import createmob1 from '../assets/createmob1.png'
import createmob2 from '../assets/createmob2.png'
import createmob3 from '../assets/createmob3.png'
import gifright from '../assets/gifright.gif'


function Howtobuy() {
    return (
        <div className='flex flex-col items-center justify-center w-full'>
            <h2 className='text-[#FFFCD3] lg:text-9xl text-4xl   my-5 '>HOW TO BUY</h2>
            <div className="lg:flex hidden flex-col items-center justify-center lg:mt-10 mt-2 gap-4">
                <img src={create} alt="create" className='w-10/12' />
                <img src={create1} alt="create1" className='w-10/12' />
                <img src={create2} alt="create2" className='w-10/12' />
            </div>
            <div className="lg:hidden flex flex-col items-center justify-center gap-4">
                <img src={createmob1} alt="createmob1" className='w-10/12' />
                <img src={createmob2} alt="createmob2" className='w-10/12' />
                <img src={createmob3} alt="createmob3" className='w-10/12' />
            </div>


            <h2 className='text-[#FFFCD3] lg:text-xl text-sm   my-5 '>2024 XRPMATT ALL RIGHTS RESERVED</h2>
            <img src={gifright} alt="gifright" className='w-[120%] max-w-none' />

        </div>
    )
}

export default Howtobuy
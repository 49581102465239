import React from 'react'
import tgbox from '../assets/tgbox.png'
import xbox from '../assets/xbox.png'
import buymatt from '../assets/buymatt.png'
import dextools from '../assets/dextools.png'
import dexscreener from '../assets/dexscreener.png'


function Header() {
    return (
        <div className=" lg:bottom-20 bottom-10 fixed lg:w-4/12 w-[95%] z-50 socialbox flex flex-row items-center justify-between lg:gap-4 border-[#FFFCD3]/50 border-[0.1px] rounded-3xl lg:p-4 p-2 bg-[#212121]/40 backdrop-blur-xl l-mt-40">
            <img src={tgbox} alt="tgbox" className='lg:w-16 w-[14%] ' />
            <img src={xbox} alt="xbox" className='lg:w-16 w-[14%] ' />
            <img src={buymatt} alt="buymatt" className='lg:w-36 w-[32%]' />
            <img src={dextools} alt="dextools" className='lg:w-16 w-[14%] ' />
            <img src={dexscreener} alt="dexscreener" className='lg:w-16 w-[14%] ' />
        </div>
    )
}

export default Header
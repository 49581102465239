import React from 'react'
import roadman from '../assets/roadman.png'
import home from '../assets/home.png'
import about from '../assets/about.png'
import tokenomics from '../assets/tokenomics.png'
import heroimg from '../assets/heroimg.png'


import edgeguysbottom from '../assets/edgeguysbottom.png'
import edgeguystop from '../assets/edgeguystop.png'


function Hero() {

    return (
        <div className='relative flex flex-col items-center justify-center  w-full '>


            <div className="relative mainborder flex flex-col  items-center justify-center mainborder border-[2px] border-[#FFFCD3] w-[95%] lg:rounded-[80px] rounded-3xl py-4 lg:mt-3 overflow-clip">

                <img src={edgeguystop} alt="edgeguystop" className='absolute lg:-top-28 -top-2  w-[110%] z-[1] max-w-none animate-pulse' />

                <div className="menupart flex flex-col lg:flex-row items-center justify-around w-full z-10 mt-7">

                    <div className="leftbtns gap-4 flex flex-row">
                        <img src={home} alt="home" className='lg:w-40 w-24 ' />
                        <img src={about} alt="about" className='lg:w-40 w-24 ' />
                    </div>

                    <h2 className='text-[#FFFCD3] lg:text-[140px] text-3xl flex lg:hidden '>XRP</h2>

                    <h1 className='text-[#FFFCD3] lg:text-[210px] text-8xl lg:-mt-5 lg:-mb-3  lg:-smy-10 p-0 -mt-4   '>MATT</h1>
                    <div className="leftbtns gap-4 lg:flex flex-row hidden ">
                        <img src={tokenomics} alt="home" className='lg:w-40 w-24 ' />
                        <img src={roadman} alt="about" className='lg:w-40 w-24 ' />
                    </div>

                </div>

                <div className=" relative miniborder border-[2px] border-[#FFFCD3] lg:rounded-[80px] rounded-3xl w-[95%] justify-center items-center flex bg-[#1C1727] overflow-clip m-4 ">
                    <img src={heroimg} alt="heroimg" className='w-[650px] -mt-16' />


                    <div className="TEXTISH absolute hidden lg:flex flex-row w-10/12 items-center justify-between  gap-20">

                        <div className="left flex flex-col items-center justify-center gap-4">
                            <h2 className='text-[#FFFCD3] lg:text-[140px] text-8xl lg:-mt-5 lg:-mb-3  lg:-smy-10 p-0  '>XRP</h2>
                            <h2 className='text-[#FFFCD3] lg:text-[140px] text-8xl lg:-mt-5 lg:-mb-3  lg:-smy-10 p-0  '>XRP</h2>
                            <h2 className='text-[#FFFCD3] lg:text-[140px] text-8xl lg:-mt-5 lg:-mb-3  lg:-smy-10 p-0  '>XRP</h2>
                        </div>


                        <div className="left flex flex-col items-center justify-center gap-4">
                            <h2 className='text-[#FFFCD3] lg:text-[140px] text-8xl lg:-mt-5 lg:-mb-3  lg:-smy-10 p-0  '>MATT</h2>
                            <h2 className='text-[#FFFCD3] lg:text-[140px] text-8xl lg:-mt-5 lg:-mb-3  lg:-smy-10 p-0  '>MATT</h2>
                            <h2 className='text-[#FFFCD3] lg:text-[140px] text-8xl lg:-mt-5 lg:-mb-3  lg:-smy-10 p-0  '>MATT</h2>
                        </div>
                    </div>

                    <img src={edgeguysbottom} alt="edgeguysbottom" className='absolute lg:-bottom-6 -bottom-2  w-[103%] z-[1] max-w-none animate-pulse' />

                </div>

                <div className="leftbtns gap-4 flex flex-row lg:hidden ">
                    <img src={tokenomics} alt="home" className='lg:w-40 w-24 ' />
                    <img src={roadman} alt="about" className='lg:w-40 w-24 ' />
                </div>


            </div>


        </div>
    )
}

export default Hero
import { useState, useRef } from 'react';
import Hero from './components/Hero';
import Rollpart from './components/Rollpart';
import About from './components/About';
import Tokenomics from './components/Tokenomics';
import Howtobuy from './components/Howtobuy';
import Roll2 from './components/Roll2';
import Header from './components/Header';

import bgmusic from './assets/musicc.mp3';
import enter from './assets/enter.png';
import logo from './assets/lilplat.png';

import video from './assets/video.MP4';


function App() {

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const handlePlayMusic = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleToggleMute = () => {
    if (audioRef.current) {
      if (isMuted) {
        audioRef.current.muted = false;
      } else {
        audioRef.current.muted = true;
      }
      setIsMuted(!isMuted);
    }
  };




  return (
    <div className="App relative flex flex-col items-center overflow-clip">


      {!isPlaying && (
        <div className="bg-black/60 backdrop-blur-xl fixed w-full h-screen flex flex-col justify-center items-center z-[60] ftop-10">
          <h2 className='text-[#FFFCD3]  lg:text-7xl text-4xl'>XRP MATT</h2>
          <video src={video} autoPlay muted loop playsInline className='lg:w-6/12 w-11/12 rounded-3xl object-cover' />
          <img
            src={enter}
            alt="Enter"
            className="w-60 p-3 z-[60] cursor-pointer"
            onClick={handlePlayMusic}
          />
        </div>
      )}
      <audio ref={audioRef} src={bgmusic} loop />








      <Header />
      <Hero />
      <Rollpart />
      <About />
      <Tokenomics />
      <Roll2 />

      <Howtobuy />


    </div>
  );
}

export default App;

import React from 'react'
import roadmap3 from '../assets/roadmap3.png'
import roadmap4 from '../assets/roadmap4.png'
import bulldude from '../assets/bulldude.png'
import roadmap1 from '../assets/roadmap1.png'
import roadmap2 from '../assets/roadmap2.png'

import supply from '../assets/supply.png'
import xrpchain from '../assets/xrpchain.png'
import lpburned from '../assets/lpburned.png'
import roadmap1mob from '../assets/roadmap1mob.png'
import roadmap2mob from '../assets/roadmap2mob.png'
import roadmap3mob from '../assets/roadmap3mob.png'
import roadmap4mob from '../assets/roadmap4mob.png'
import gunn from '../assets/gunn.png'

function Tokenomics() {
    return (
        <div className='w-full flex flex-col items-center justify-center'>
            <div className="relative mainborder2 bg-[#1C1727] flex flex-col  items-center justify-center mainborder border-[2px] border-[#FFFCD3] w-[95%] lg:rounded-[80px] rounded-3xl py-4 lg:mt-3 overflow-clip ">
                <h2 className='text-[#FFFCD3] lg:text-[140px] text-[36px] lg:-mt-1    lg:my-10 my-5 p-0  '>TOKENOMICS</h2>

                <div className="THREE flex lg:flex-row flex-col items-center justify-center gap-4 p-3">
                    <img src={supply} alt="supply" className='w-96' />
                    <img src={xrpchain} alt="xrpchain" className='w-96' />
                    <img src={lpburned} alt="lpburned" className='w-96' />
                </div>
                <img src={gunn} alt="gunn" className='w-10/12 lg:mt-10 mt-5 -mb-14 lg:-mb-60' />
                <h2 className='text-[#FFFCD3] lg:text-[140px] text-[36px]     lg:my-20 my-5 p-0  '>ROADMAP</h2>
                <div className="lg:flex flex-col items-center justify-center gap-4 hidden mb-10">
                    <img src={roadmap1} alt="roadmap1" className='w-11/12   ' />
                    <img src={roadmap2} alt="roadmap2" className='w-11/12   ' />
                    <img src={roadmap3} alt="roadmap3" className='w-11/12   ' />
                    <img src={roadmap4} alt="roadmap4" className='w-11/12   ' />
                </div>

                <div className="lg:hidden flex flex-col items-center justify-center gap-4 ">
                    <img src={roadmap1mob} alt="roadmap1mob" className='w-11/12   ' />
                    <img src={roadmap2mob} alt="roadmap2mob" className='w-11/12   ' />
                    <img src={roadmap3mob} alt="roadmap3mob" className='w-11/12   ' />
                    <img src={roadmap4mob} alt="roadmap4mob" className='w-11/12   ' />
                </div>

            </div>


        </div>
    )
}

export default Tokenomics